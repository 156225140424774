<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Obras / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
                <div class="px-5 pb-4">
                    
                    <div class="grid grid-cols-12 gap-6">
                        <div v-if="$store.state.user.root" class="col-span-12 md:col-span-12">
                            <label for="empresa" class="block text-sm font-medium">Empresa</label>
                            <select v-model="form.empresa" id="empresa" name="empresa" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="e in empresas" :key="e._id" :value="e._id">{{e.nome}}</option>
                            </select>
                        </div>

                        <div class="col-span-12 md:col-span-12">
                            <label for="nome" class="block text-sm font-medium">Nome Obra</label>
                            <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div> 
                        
                    </div>
                     <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-4">
                            <label for="fase" class="block text-sm font-medium">Fase do Projeto</label>
                            <select v-model="form.fase" id="fase" name="fase" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option value="Pré-obra">Pré-obra</option>
                                <option value="Em obra">Em obra</option>
                                <option value="Projeto concluído">Projeto concluído</option>
                            </select>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="dataInicio" class="block text-sm font-medium">Data Início</label>
                            <datepicker v-model="form.dataInicio" :language="ptBR" name="dataInicio" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="dataFim" class="block text-sm font-medium">Data Fim</label>
                            <datepicker v-model="form.dataFim" :language="ptBR" name="dataFim" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <label for="cep" class="block text-sm font-medium">Cep</label>
                            <input @blur="buscaCep" v-model="form.cep" type="text" name="cep" id="cep" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-9">
                            <label for="endereco" class="block text-sm font-medium">Endereço</label>
                            <input v-model="form.endereco" type="text" name="endereco" id="endereco" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-3">
                            <label for="numero" class="block text-sm font-medium">Número</label>
                            <input v-model="form.numero" type="text" name="numero" id="numero" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="bairro" class="block text-sm font-medium">Bairro</label>
                            <input v-model="form.bairro" type="text" name="bairro" id="bairro" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="cidade" class="block text-sm font-medium">Cidade</label>
                            <input v-model="form.cidade" type="text" name="cidade" id="cidade" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <label for="estado" class="block text-sm font-medium">Estado</label>
                            <input v-model="form.estado" type="text" name="estado" id="estado" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                   
                </div>
                
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';

export default {
    data() {
        return {
            empresas: [],
            ptBR: ptBR,
            route: 'obras',
            form: {
                nome: '',
                fase: 'Em obra',
                cidade: '',
                cep: '',
                estado: '',
                endereco: '',
                numero: '',
                bairro: '',
                dataInicio: '',
                dataFim: ''
            }
        }
    },
    methods: {
        async buscaCep() {
            if (!this.form.cep) return;
            
            const response = await this.$http.get(`/v1/cep/${this.form.cep}`);

            console.log(response);

            if (response.data.erro === undefined) {
                this.form.cidade = response.data.localidade;
                this.form.estado = response.data.uf;
                this.form.endereco = response.data.logradouro;
                this.form.bairro = response.data.bairro;
            }
        },
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back()
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    components: {
        Datepicker
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }

        const reqEmp = await this.$http.post("/v1/empresas/list");
        this.empresas = reqEmp.data.data;
    },
}
</script>